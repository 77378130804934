.bar {
    height: 1vh;
    background-color: #394e6f;
    width: 100%;
    display: block;
    margin: 10px 0 0 0;
}

.container-wrap2 {
    padding-left: 150px;
    padding-right: 150px;
    position: relative;
    height: 100%;
    width: 100%;
}

.container-wrap {
    padding-left: 40px;
    padding-right: 40px;
}

@media screen and (max-width: 768px) {
    .container-wrap {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.app-header {
    display: block;
    font-family: "DIN W01 Bold";
    height: auto;
    position: relative;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 200;
}

.header-inner {
    height: 100vh;
    position: relative;
    z-index: 5;
}

.bar-background {
    background-color: transparent;
    z-index: 2;
    height: 70px;
    width: 100%;
    padding: 0;
}

.container-wrap2 {
    padding-left: 150px;
    padding-right: 150px;
    position: relative;
    height: 100%;
    width: 100%;
}

.navbar,
.navbar-dark {
    border: 1px solid transparent;
    color: #FFFFFF;
    font-family: "DIN W01 Bold";
    min-height: 70px;
    opacity: 1;
    z-index: 3;
    position: fixed;
}

.navbar-default,
.navbar-toggle,
.icon-bar {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.icon-bar {
    width: 22px;
    height: 2px;
    background-color: #B6B6B6;
    display: block;
    transition: all 0.2s;
    margin-top: 4px;
}

.navbar-toggler {
    border: none;
    background: transparent !important;
}

.navbar-toggler .top-bar {
    transform: rotate(45deg);
    transform-origin: 10% 10%;
}

.navbar-toggler .middle-bar {
    opacity: 0;
}

.navbar-toggler .bottom-bar {
    transform: rotate(-45deg);
    transform-origin: 10% 90%;
}

.navbar-toggler.collapsed .top-bar {
    transform: rotate(0);
}

.navbar-toggler.collapsed .middle-bar {
    opacity: 1;
}

.navbar-toggler.collapsed .bottom-bar {
    transform: rotate(0);
}

.nav-logo {
    width: 38%;
    height: auto;
    min-height: 6vh;
    padding-left: 0px;
}

@media screen and (max-width: 1440px) {
    .nav-logo {
        width: 40%;
        padding-left: 0px;
    }
}

@media screen and (max-width: 1024px) {
    .nav-logo {
        width: 80%;
        padding-left: 0px;
    }
}

@media screen and (max-width: 768px) {
    .nav-logo {
        width: 200%;
        padding-left: 0px;
    }
}

@media screen and (max-width: 375px) {
    .nav-logo {
        width: 200px;
        padding-left: 0px;
    }
}

.navbar-brand {
    width: 50%;
    height: auto;
    padding-left: 150px;
    padding-right: 150px;
}

@media screen and (max-width: 1024px) {
    .navbar-brand {
        width: 20%;
        padding: 0;
    }
}

@media screen and (max-width: 768px) {
    .navbar-brand {
        width: 20%;
        padding: 0;
    }
}

@media screen and (max-width: 375px) {
    .navbar-brand {
        padding: 0;
    }
}

.nav-menu {
    border-bottom: 3px solid transparent;
    color: #000000 !important;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    font-size: 13px;
    font-weight: bold !important;
}

.nav-link {
    border-bottom: 3px solid transparent;
    color: #FFFFFF !important;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 80%;
    font-size: 13px;
    font-weight: bold !important;
    text-transform: none;
    font-family: "DIN W01 Bold";
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.4);
}

.nav-link .nav-link li {
    opacity: 0;
}

.nav-link:hover {
    color: #d4d5d6 !important;
}

@media screen and (max-width: 812px) and (orientation: landscape) {
    .nav-link {
        position: absolute;
        right: 0px;
        height: 70vh;
        background-color: #000000;
        display: flex;
        align-items: center;
        width: 100%;
        transform: traslateX(100%);
        justify-content: normal;
        flex-direction: row;
        align-items: center;
        border: 1px solid white;
    }

    .nav-link li {
        opacity: 1;
        padding: 30px;
    }
}

@media screen and (max-width: 768px) {
    .nav-link {
        position: absolute;
        right: 0px;
        height: 82vh;
        background-color: #000000;
        display: flex;
        align-items: center;
        width: 70%;
        transform: traslateX(100%);
        justify-content: normal;
        flex-direction: column;
        align-items: center;
        border: 1px solid white;
    }

    .nav-link li {
        opacity: 1;
        padding: 30px;
    }
}

.icon-nav {
    width: 1em;
    margin-top: -5px;
}

@media screen and (max-width: 768px) {
    .icon-nav {
        width: 0.7em;
    }
}

@media screen and (max-width: 375px) {
    .icon-nav {
        width: 0.5em;
    }
}

.icon {
    border-bottom: 3px solid transparent;
    color: #FFFFFF;
    display: block;
    font-size: 25px;
    font-weight: 500;
    margin: 5px 12px;
    padding: 10px 2px 5px;
    width: inherit;
}

.icon:hover {
    color: #394e6f;
}

.search-icon {
    content: url("https://api.iconify.design/octicon-search.svg?color=%23ffffff&width=18px");
}

.search-icon:hover {
    content: url("https://api.iconify.design/octicon-search.svg?color=%23fbe28f&width=18px");
}

@media screen and (max-width: 1024px) {
    .icon {
        font-size: 25px;
        margin: 0 1vh;
    }
}

@media screen and (max-width: 768px) {
    .icon {
        font-size: 25px;
        margin: 0 1.3vh;
    }
}

@media screen and (max-width: 375px) {
    .icon {
        font-size: 25px;
        margin: 0 0.5vh;
    }
}

.hero1 {
    width: 100%;
}

/* .carousel-hero {
  background-color: none;
  color: #FFFFFF;
  height: 85vh;
  left: 50%;
  margin-top: -10vh;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}  */

/* @media screen and (max-width: 1024px) {
  .carousel-hero {
    height: 60vh;
    margin-top: -6vh;
  }
}

@media screen and (max-width: 768px) {
  .carousel-hero {
    height: 45vh;
    margin-top: -5vh;
  }
}

@media screen and (max-width: 375px) {
  .carousel-hero {
    height: 35vh;
    margin-top: -7vh;
  }
}

.description {
    display: block;
    font-family: "DIN W01 Bold";
    font-size: 70px;
    margin-bottom: 1vh;
    margin-top: 5vh;
    color: #FFFFFF;
}

@media screen and (max-width: 1440px) {
    .description {
        font-size: 55px;
        margin-bottom: 1vh;
        margin-top: 25vh;
    }
}

@media screen and (max-width: 1024px) {
    .description {
        font-size: 50px;
        margin-bottom: 1vh;
        margin-top: 25vh;
    }
}

@media screen and (max-width: 768px) {
    .description {
        font-size: 40px;
        margin-bottom: 1vh;
        margin-top: 25vh;
    }
}

@media screen and (max-width: 375px) {
    .description {
        font-size: 24px;
        margin-bottom: 1vh;
        margin-top: 40vh;
    }
}

@media screen and (max-width: 812px) and (orientation: landscape) {
    .description {
        position: absolute;
        left: 10%;
        right: 10%;
        display: block;
        font-size: 30px;
        top: 0px;
    }
}

/* .title {
  font-family: "DIN W01 Bold";
  font-size: 120px;
  font-weight: bold;
  margin: -1vh;
  color: #FFFFFF;
} */

/* @media screen and (max-width: 1440px) {
  .title {
    font-size: 70px;
  }
}

@media screen and (max-width: 1024px) {
  .title {
    font-size: 65px;
  }
}

@media screen and (max-width: 812px) and (orientation: landscape) {
  .title {
    position: absolute;
    display: block;
    right: 10%;
    left: 10%;
    font-size: 45px;
    bottom: -150px;
  }
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 50px;
  }
}

@media screen and (max-width: 375px) {
  .title {
    font-size: 27px;
  }
}

.title:nth-child(3) {
  margin-bottom: 40px;
} */

.topic-header-about {
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center;
}

.topic-header {
    text-align: center;
}

.white-line {
    background-color: #FFFFFF;
    height: 3px;
    margin-bottom: 40px;
    width: 60px;
}

.learn-more {
    border: 1px solid #FFFFFF;
    color: #394e6f;
    font-family: "DIN W01 Bold";
    font-size: 15px;
    font-weight: bold;
    margin: 2vh auto 2vh auto;
    padding: 10px 25px;
    text-align: center;
    text-transform: uppercase;
}

.learn-more:hover {
    color: #FFFFFF;
    text-decoration: none;
}

@media screen and (max-width: 1024px) {
    .learn-more {
        font-size: 2.3vh;
    }
}

@media screen and (max-width: 768px) {
    .learn-more {
        border: 0.2vh solid #FFFFFF;
        font-size: 2.3vh;
        margin-top: 0.5vh;
        padding: 0.6vh;
    }
}

@media screen and (max-width: 375px) {
    .learn-more {
        border: 0.5vh solid #FFFFFF;
        font-size: 1.8vh;
        margin-top: -0.5vh;
        padding: 0.6vh;
    }
}

.inner {
    border-top: 1px solid #FFFFFF;
    color: #faf3e0;
    font-size: 2vh;
    margin: 0 0vh 0 0vh;
    padding: 10px 0;
    position: relative;
    text-transform: uppercase;
}

.inner-number {
    font-size: 2vh;
    font-weight: bold;
    position: sticky;
    color: #FFFFFF;
}

.inner-text {
    font-size: 1.8vh;
    font-weight: bold;
    text-transform: uppercase;
    color: #FFFFFF;
}

.progress {
    background-color: #FFFFFF;
    border-radius: 0;
    font-size: 0;
    height: 3px;
    width: 25%;
}

.progress-bar {
    background-color: #FFFFFF;
    height: 3px;
}

.about-text {
    margin: auto;
    margin-bottom: 3.5rem;
}

.about {
    width: 100%;
}

.about-heading {
    font-family: "DIN W01 Bold";
    font-size: 34px;
    color: #394e6f;
    margin-top: 4.5rem;
    text-align: center;
}

.about-subheading {
    font-family: "DIN W01 Bold";
    font-size: 30px;
    color: #394e6f;
    font-weight: bold;
    margin-top: 1rem;
    text-align: center;
}

.about-red-line {
    background-color: #f18283;
    height: 3px;
    margin-bottom: 2rem;
    margin-top: 2.5rem;
    width: 60px;
}

.lorem {
    font-size: 2rem!important;
    color: #6a909b;
    font-family: "DIN W01 Bold";
    font-size: 20px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 700px) {
    .lorem {
        font-size: 1.3rem !important;
    }
}

.lorem1 {
    font-size: 2rem !important;
    color: #6a909b;
    font-family: "DIN W01 Bold";
    font-weight: bold;
    font-size: 25px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 700px) {
    .lorem1 {
        font-size: 1.3rem !important;
    }
}

.lorem2 {
    font-size: 2rem !important;
    color: #6a909b;
    text-shadow: 1px 1px 2px #000000;
    font-family: "DIN W01 Bold";
    font-size: 20px;
    width: 90%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 700px) {
    .lorem2 {
        font-size: 1.3rem !important;
    }
}

.lorem2:hover {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}

.lorem3 {
    font-size: 2rem !important;
    color: #6a909b;
    font-family: "DIN W01 Bold";
    font-size: 20px;
    width: 90%;
    text-align: left;
    margin-left: 40px;
}

.about-images {
    margin-bottom: 5.5rem;
}

.image-page {
    width: 55%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.about-text-header {
    margin-left: auto;
    margin-right: auto;
}

.overlay-div {
    position: relative;
    width: 100%;
}

.overlay {
    display: block;
    left: 0.5rem;
    opacity: 0;
    position: absolute;
    top: -0.5rem;
    transition: 0.3s ease;
    width: 100%;
}

.overlay:hover {
    opacity: 1;
}

.float {
    position: fixed;
    width: 30px;
    height: 30px;
    bottom: 20px;
    right: 0px;
    background-color: transparent;
    z-index: 100;
    display: none;
    text-align: center;
}

.float:hover {
    box-shadow: 2px 2px 6px #999;
}

.my-float {
    margin-top: 22px;
}

.team {
    left: 17%;
    position: absolute;
    top: 45%;
    transform: translate(-50%, -50%);
}

@media screen and (max-width: 1024px) {
    .team {
        left: 18%;
        top: 45%;
    }
}

@media screen and (max-width: 768px) {
    .team {
        left: 19%;
        top: 42%;
    }
}

@media screen and (max-width: 375px) {
    .team {
        left: 49%;
        top: 49%;
    }
}

.users-super-team {
    color: #FFFFFF;
    font-family: "DIN W01 Bold";
    font-size: 18px;
    font-weight: bold;
    left: 12%;
    position: absolute;
    top: 50%;
}

@media screen and (max-width: 1024px) {
    .users-super-team {
        left: 11%;
        top: 50%;
    }
}

@media screen and (max-width: 768px) {
    .users-super-team {
        font-size: 15px;
        left: 12%;
        top: 48%;
    }
}

@media screen and (max-width: 375px) {
    .users-super-team {
        left: 37%;
        top: 52%;
    }
}

.counter {
    background-color: #394e6f;
    color: #FFFFFF;
    font-family: "DIN W01 Bold";
    font-size: 14px;
    padding: 0 5px;
}

@media screen and (max-width: 1024px) {
    .counter {
        font-size: 12px;
    }
}

@media screen and (max-width: 768px) {
    .counter {
        font-size: 10px;
    }
}

@media screen and (max-width: 375px) {
    .counter {
        font-size: 8px;
    }
}

.counter-div {
    border-left: 1px solid rgba(255, 255, 255, 0.35);
    border-right: 1px solid rgba(255, 255, 255, 0.35);
    display: inline-block;
    height: auto;
    padding: 60px 5px;
    text-align: center;
    width: 20%;
}

@media screen and (max-width: 1024px) {
    .counter-div {
        padding: 50px 5px;
    }
}

@media screen and (max-width: 768px) {
    .counter-div {
        padding: 40px 5px;
    }
}

@media screen and (max-width: 375px) {
    .counter-div {
        padding: 30px 5px;
    }
}

.counter-number {
    font-size: 72px;
    font-weight: bold;
    line-height: 100%;
    margin-bottom: 10px;
}

@media screen and (max-width: 1024px) {
    .counter-number {
        font-size: 60px;
    }
}

@media screen and (max-width: 768px) {
    .counter-number {
        font-size: 50px;
    }
}

@media screen and (max-width: 375px) {
    .counter-number {
        font-size: 40px;
    }
}

/* .design-div {
  background-image: url("./assets/front-page.jpg") no-repeat center center/cover;
  display: block;
  font-family: "DIN W01 Bold";
  height: auto;
  padding: 30px;
  position: relative;
  display: block;
  background-attachment: fixed;
  background-position: center;
  background-repeat: repeat;
} */

.design-heading {
    font-family: "DIN W01 Bold";
    font-size: 24px;
    margin-top: 2rem;
    color: #6da4b5;
    text-align: center;
    text-transform: uppercase;
}

.design-subheading {
    font-family: "DIN W01 Bold";
    font-size: 30px;
    color: #6da4b5;
    font-weight: bold;
    margin-top: 1rem;
    text-align: center;
    text-transform: uppercase;
}

.design-red-line {
    background-color: #f18283;
    height: 3px;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    width: 60px;
}

.focus-icon {
    width: 4%;
    margin-right: 10px;
}

.grid-design-item0 {
    grid-area: text1;
}

.grid-design-item1 {
    grid-area: text2;
}

.grid-design-item2 {
    grid-area: image1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
    margin-bottom: 20px;
}

@media only screen and (max-width: 1024px) {
    .grid-design-item2 {
        display: grid;
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 768px) {
    .grid-design-item2 {
        display: grid;
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 400px) {
    .grid-design-item2 {
        display: grid;
        grid-template-columns: 1fr;
    }
}

.grid-design-item3 {
    grid-area: image2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
    margin-bottom: 20px;
}

@media only screen and (max-width: 1024px) {
    .grid-design-item3 {
        display: grid;
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 768px) {
    .grid-design-item3 {
        display: grid;
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 400px) {
    .grid-design-item3 {
        display: grid;
        grid-template-columns: 1fr;
    }
}

.grid-design-item4 {
    grid-area: image3;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
    margin-bottom: 80px;
}

@media only screen and (max-width: 1024px) {
    .grid-design-item4 {
        display: grid;
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 768px) {
    .grid-design-item4 {
        display: grid;
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 400px) {
    .grid-design-item4 {
        display: grid;
        grid-template-columns: 1fr;
    }
}

.grid-design-container {
    display: grid;
    grid-template-areas: "text1" "text2" "image1" "image2" "image3";
    grid-gap: 10px;
}

.grid-design-cointainer>div {
    text-align: center;
}

.grid-our-app {
    width: auto;
    display: inline-block;
}

@media only screen and (max-width: 400px) {
    .grid-our-app {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        align-items: center;
    }
}

.our-app {
    display: inline-block;
    margin-left: 5px;
    margin-right: auto;
    width: auto;
    box-shadow: 1px 1px 10px #394e6f;
}

.our-app-first {
    display: inline-block;
    margin-left: 20px;
    margin-right: auto;
    width: auto;
    box-shadow: 1px 1px 10px #394e6f;
}

* {
    box-sizing: border-box;
}

.design-textarea {
    display: inline-flex;
    font-family: "DIN W01 Bold";
    color: #394e6f;
    font-size: 1.5em;
    width: 80%;
    margin-left: 20px;
}

@media only screen and (max-width: 1440px) {
    .design-textarea {
        font-size: 1em;
    }
}

.design-tablets {
    width: 99%;
    margin-left: 20px;
    margin-right: 20px;
    padding: 25px;
}

.design-tablets-image {
    margin-left: 20px;
    margin-right: 20px;
    width: 99%;
    height: auto;
}

.design-our-app {
    width: 99%;
    margin-left: 20px;
    margin-right: 20px;
    padding: 25px;
}

.design-image {
    width: 60%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.vin-image {
    width: 30%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.vin-output {
    text-align: center;
}

.corporate-tablets-image {
    margin-left: 20px;
    margin-right: 20px;
    width: 99%;
    height: auto;
}

.corporate-textarea {
    display: inline-flex;
    font-family: "DIN W01 Bold";
    font-size: 1.2em;
    color: #394e6f;
    width: 95%;
    margin-left: 20px;
}

@media only screen and (max-width: 1440px) {
    .corporate-textarea {
        font-size: 1em;
    }
}

.corporate-tablets {
    width: 96%;
    margin-left: 20px;
    padding: 5px 0 0px 20px;
}

.corporate-pconnect {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.customizations-tablets-image {
    margin-left: 20px;
    margin-right: 20px;
    width: 99%;
    height: auto;
}

.customizations-textarea {
    display: inline-flex;
    font-family: "DIN W01 Bold";
    font-size: 1.2em;
    color: #394e6f;
    width: 95%;
    margin-left: 20px;
}

@media only screen and (max-width: 1440px) {
    .customizations-textarea {
        font-size: 1em;
    }
}

.customizations-tablets {
    width: 96%;
    margin-left: 20px;
    padding: 5px 0 0px 20px;
}

.mobility-tablets-image {
    margin-left: 20px;
    margin-right: 20px;
    width: 99%;
    height: auto;
}

.mobility-textarea {
    display: inline-flex;
    font-family: "DIN W01 Bold";
    font-size: 1.4em;
    color: #394e6f;
    width: 95%;
    margin-left: 20px;
}

@media only screen and (max-width: 1440px) {
    .mobility-textarea {
        font-size: 1em;
    }
}

.mobility-tablets {
    width: 96%;
    margin-left: 20px;
    padding: 5px 0 0px 20px;
}

.electric-tablets-image {
    margin-left: 20px;
    margin-right: 20px;
    width: 99%;
    height: auto;
}

.electric-textarea {
    display: inline-flex;
    font-family: "DIN W01 Bold";
    font-size: 1.4em;
    color: #394e6f;
    width: 95%;
    margin-left: 20px;
}

@media only screen and (max-width: 1440px) {
    .electric-textarea {
        font-size: 1em;
    }
}

.electric-tablets {
    width: 96%;
    margin-left: 20px;
    padding: 5px 0 0px 20px;
}

.telematics-textarea {
    display: inline-flex;
    font-family: "DIN W01 Bold";
    color: #394e6f;
    font-size: 1.5em;
    width: 80%;
    margin-left: 20px;
}

@media only screen and (max-width: 1440px) {
    .telematics-textarea {
        font-size: 1em;
    }
}

.telematics-tablets {
    width: 99%;
    margin-left: 20px;
    margin-right: 20px;
    padding: 25px;
}

.telematics-tablets-image {
    margin-left: 20px;
    margin-right: 20px;
    width: 99%;
    height: auto;
}

.intelligence-tablets-image {
    margin-left: 20px;
    margin-right: 20px;
    width: 99%;
    height: auto;
}

.intelligence-textarea {
    display: inline-flex;
    font-family: "DIN W01 Bold";
    font-size: 1.2em;
    color: #394e6f;
    border: 1px solid transparent;
    width: 95%;
    margin-left: 20px;
}

@media only screen and (max-width: 1440px) {
    .intelligence-textarea {
        font-size: 1em;
    }
}

.intelligence-tablets {
    width: 96%;
    margin-left: 20px;
    padding: 5px 0 0px 20px;
}

.card {
    border: none;
}

.consulting-tablets-image {
    margin-left: 20px;
    margin-right: 20px;
    width: 99%;
    height: auto;
}

.consulting-textarea {
    display: inline-flex;
    font-family: "DIN W01 Bold";
    font-size: 1.4em;
    color: #394e6f;
    width: 95%;
    margin-left: 20px;
}

@media only screen and (max-width: 1440px) {
    .consulting-textarea {
        font-size: 1em;
    }
}

.consulting-tablets {
    width: 96%;
    margin-left: 20px;
    padding: 5px 0 0px 20px;
}

.connected-tablets-image {
    margin-left: 20px;
    margin-right: 20px;
    width: 99%;
    height: 400px;
}

.connected-textarea {
    display: inline-flex;
    font-family: "DIN W01 Bold";
    font-size: 1.4em;
    color: #394e6f;
    width: 95%;
    margin-left: 20px;
}

@media only screen and (max-width: 1440px) {
    .connected-textarea {
        font-size: 1em;
    }
}

.connected-tablets {
    width: 96%;
    margin-left: 20px;
    padding: 5px 0 0px 20px;
}

.professional-textarea {
    display: inline-flex;
    font-family: "DIN W01 Bold";
    font-size: 1.4em;
    color: #394e6f;
    width: 95%;
    margin-left: 20px;
}

@media only screen and (max-width: 1440px) {
    .professional-textarea {
        font-size: 1em;
    }
}

.professional-tablets {
    width: 96%;
    margin-left: 20px;
    padding: 5px 0 0px 20px;
}

.professional-tablets-image {
    margin-left: 20px;
    margin-right: 20px;
    width: 99%;
    height: auto;
}

.grid-what-item0 {
    grid-area: text1;
}

.grid-what-item1 {
    grid-area: image1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
    margin-bottom: 20px;
}

@media only screen and (max-width: 1024px) {
    .grid-what-item1 {
        display: grid;
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 768px) {
    .grid-what-item1 {
        display: grid;
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 375px) {
    .grid-what-item1 {
        display: grid;
        grid-template-columns: 1fr;
    }
}

.grid-what-item2 {
    grid-area: image2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
    margin-bottom: 20px;
}

@media only screen and (max-width: 1024px) {
    .grid-what-item2 {
        display: grid;
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 768px) {
    .grid-what-item2 {
        display: grid;
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 375px) {
    .grid-what-item2 {
        display: grid;
        grid-template-columns: 1fr;
    }
}

.grid-what-container {
    display: grid;
    grid-template-areas: "text1" "image1" "image2";
    grid-gap: 10px;
}

.grid-what-cointainer>div {
    text-align: center;
}

.what-textarea {
    display: inline-flex;
    font-family: "DIN W01 Bold";
    font-size: 1.5em;
    color: #394e6f;
    border: 1px solid transparent;
    width: 80%;
    margin-left: 20px;
}

@media only screen and (max-width: 1440px) {
    .what-textarea {
        font-size: 1em;
    }
}

.what-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.7;
}

.what-tablets {
    width: 99%;
    margin-left: 20px;
    padding: 110px 0 110px 20px;
    box-shadow: 1px 1px 10px #394e6f;
}

.ai-tablets {
    width: 99%;
    margin-left: 20px;
    padding: 5px 0 0px 20px;
}

.ai-textarea {
    display: inline-flex;
    font-family: "DIN W01 Bold";
    font-size: 1.4em;
    color: #394e6f;
    width: 80%;
    margin-left: 20px;
}

@media only screen and (max-width: 1440px) {
    .ai-textarea {
        font-size: 1em;
    }
}

.ai-tablets-image {
    margin-left: 20px;
    margin-right: 20px;
    width: 99%;
    height: auto;
}

.ml-tablets {
    width: 99%;
    margin-left: 20px;
    padding: 5px 0 0px 20px;
}

.ml-textarea {
    display: inline-flex;
    font-family: "DIN W01 Bold";
    font-size: 1.4em;
    color: #394e6f;
    width: 80%;
    margin-left: 20px;
}

@media only screen and (max-width: 1440px) {
    .ml-textarea {
        font-size: 1em;
    }
}

.ml-tablets-image {
    margin-left: 20px;
    margin-right: 20px;
    width: 99%;
    height: auto;
}

.what-we-do {
    align-items: center;
    display: table-cell;
    vertical-align: middle;
}

.what-collapse-div {
    margin-bottom: 5.5rem;
}

.what-text {
    margin: auto;
    margin-bottom: 3.5rem;
    width: 83%;
}

.what-heading {
    font-family: "DIN W01 Bold";
    font-size: 24px;
    color: #6da4b5;
    margin-top: 4.5rem;
    text-align: center;
}

.what-subheading {
    font-family: "DIN W01 Bold";
    font-size: 30px;
    color: #6da4b5;
    font-weight: bold;
    margin-top: 1rem;
    text-align: center;
    text-transform: uppercase;
}

.what-red-line {
    background-color: #f18283;
    height: 3px;
    margin-bottom: 2rem;
    margin-top: 2.5rem;
    width: 60px;
}

.dropdown-menu {
    color: #FFFFFF;
    font-family: "DIN W01 Bold";
    font-size: 14px;
}

.dropdown-menu:hover {
    color: #000000;
}

.dropdown:hover>.dropdown-menu {
    display: block;
    background-color: #000000;
    opacity: 1;
}

.dropdown:hover>a {
    color: white;
}

.dropdown-item {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: bold;
    background: none;
    border: none;
    font-family: "DIN W01 Bold";
}

.contact-image {
    width: 35%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 700px) {
    .contact-image {
        width: 80%;
    }
}

.card-header {
    background-color: transparent;
}

.card-body {
    color: #394e6f;
    font-family: "DIN W01 Bold";
    font-size: 15px;
    font-style: italic;
    height: auto;
    overflow-y: scroll;
}

::-webkit-scrollbar {
    color: #97e1d3;
    height: 5px;
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background: #97e1d3;
    height: 5px;
}

.what-we-do .iconify {
    color: #97e1d3;
    font-size: 25px;
    position: relative;
    right: 5px;
    top: 2px;
}

.picture-icon {
    content: url("https://api.iconify.design/ant-design:picture-outline.svg?color=%2397e1d3&height=20");
}

.sliders-icon {
    content: url("https://api.iconify.design/uil:sliders-v-alt.svg?color=%2397e1d3&height=20");
}

.target-icon {
    content: url("https://api.iconify.design/feather:target.svg?color=%2397e1d3&height=20");
}

.accordion-toggle::after {
    content: url("https://api.iconify.design/ant-design:arrow-up-outline.svg?color=%23999&height=20");
    float: right;
    font-size: 20px;
}

.accordion-toggle.collapsed::after {
    content: url("https://api.iconify.design/ant-design:arrow-down-outline.svg?color=%23999&height=20");
}

.testimonial-div {
    background-color: #394e6f;
    width: 100%;
}

.carousel-testimonial {
  align-items: center;
  display: flex;
  height: 250px;
}

@media screen and (max-width: 1440px) {
  .carousel-testimonial {
    height: 200px;
  }
}

.testimonial-inner {
    align-items: center;
    width: 70%;
}

.carousel-control-prev {
  margin-left: -10vw;
}

.carousel-control-next {
  margin-right: -10vw;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='gray' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='gray' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.speech-bubble {
    display: block;
    float: left;
    height: 8vw;
    margin-left: 1rem;
    width: 8vw;
}

@media screen and (max-width: 1440px) {
    .speech-bubble {
        height: 8rem;
        width: 8rem;
    }
}

@media screen and (max-width: 1024px) {
    .speech-bubble {
        height: 6rem;
        width: 6rem;
    }
}

@media screen and (max-width: 768px) {
    .speech-bubble {
        height: 3.5rem;
        width: 3.5rem;
    }
}

.testimonial-text {
    color: #FFFFFF;
    display: block;
    float: right;
    font-family: "DIN W01 Bold";
    font-size: 1.25vw;
    font-style: italic;
}

@media screen and (max-width: 1024px) {
    .testimonial-text {
        font-size: 1.6vw;
    }
}

@media screen and (max-width: 768px) {
    .testimonial-text {
        font-size: 1.8vw;
    }
}

@media screen and (max-width: 375px) {
    .testimonial-text {
        font-size: 3vw;
    }
}

.red-line-testimonial {
    background-color: #f18283;
    display: inline-block;
    height: 3px;
    width: 60px;
}

.testimonial-name {
    color: #FFFFFF;
    bottom: 10px;
    display: inline;
    font-family: "DIN W01 Bold";
    font-size: 24px;
    left: 10px;
    margin-bottom: 5px;
    position: relative;
}

@media screen and (max-width: 768px) {
    .testimonial-name {
        font-size: 18px;
    }
}

.grid-work-item1 {
    grid-area: text1;
}

.grid-work-item2 {
    grid-area: text2;
}

.grid-work-item3 {
    grid-area: text3;
}

.grid-work-item4 {
    grid-area: img1;
    width: 80%;
}

.grid-work-item5 {
    grid-area: img2;
    width: 60%;
}

.grid-work-item6 {
    grid-area: img3;
    width: 80%;
}

.grid-work-item7 {
    grid-area: text4;
}

.grid-work-item8 {
    grid-area: text5;
}

.grid-work-item9 {
    grid-area: text6;
}

.grid-work-item10 {
    grid-area: img4;
    width: 80%;
}

.grid-work-item11 {
    grid-area: img5;
    width: 80%;
}

.grid-work-item12 {
    grid-area: img6;
    width: 80%;
}

.grid-work-container div {
    width: 100%;
}

.grid-work-container {
    display: grid;
    grid-template-areas: "text1 text2 text3" "img1 img2 img3 " "text4 text5 text6" "img4 img5 img6";
    grid-gap: 0;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
}

.grid-work-container>div {
    text-align: center;
    padding: 20px 0;
    font-size: 30px;
}

.work-text {
    margin: auto;
    margin-bottom: 3.5rem;
    width: 83%;
}

.work-heading {
    font-family: "DIN W01 Bold";
    font-size: 24px;
    color: #6da4b5;
    margin-top: 0.5rem;
    text-align: center;
    text-transform: uppercase;
}

.work-subheading {
    font-family: "DIN W01 Bold";
    font-size: 30px;
    color: #6da4b5;
    font-weight: bold;
    margin-top: 1rem;
    text-align: center;
    text-transform: uppercase;
}

.work-red-line {
    background-color: #f18283;
    height: 3px;
    margin-bottom: 2rem;
    margin-top: 2.5rem;
    width: 60px;
}

.header-work {
    padding: 32px;
    text-align: center;
}

.row-work {
    -ms-flex-wrap: wrap;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
}

.column-work {
    -ms-flex: 25%;
    flex: 25%;
    max-width: 25%;
}

.column-work img {
    vertical-align: middle;
    width: 100%;
}

@media screen and (max-width: 800px) {
    .column-work {
        -ms-flex: 50%;
        flex: 50%;
        max-width: 50%;
    }
}

@media screen and (max-width: 600px) {
    .column-work {
        -ms-flex: 100%;
        flex: 100%;
        max-width: 100%;
    }
}

.work-image {
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.message-error {
    color: #ac9551;
    text-align: center;
    padding: 10px;
    margin: 0 auto;
    display: block;
    font-size: 1.5rem;
}

.contact-card {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 70px;
    background-color: transparent;
}

.contact-label {
    color: #6a219a;
    font-size: 1.5em;
}

.contact-submit {
    width: 100%;
}

@media screen and (max-width: 400px) {
    .contact-submit {
        text-align: center;
        width: 60%;
    }
}

.contact-textarea {
    width: 100%;
}

@media screen and (max-width: 400px) {
    .contact-textarea {
        text-align: center;
        width: 60%;
    }
}

.contact-select {
    width: 100%;
}

@media screen and (max-width: 400px) {
    .contact-select {
        text-align: center;
        width: 60%;
    }
}

.contact-greeting {
    font-family: "DIN W01 Bold";
    font-size: 44px;
    text-align: center;
    margin-bottom: 40px;
}

div.contact-header {
    font-size: 3em;
    font-family: "DIN W01 Bold";
    color: #6a219a;
    text-align: center;
}

@media screen and (max-width: 700px) {
    .contact-header {
        margin-left: 30px;
    }
}

p.contact-header {
    font-size: 1.7em;
    font-family: "DIN W01 Bold";
    width: auto;
    color: #394e6f;
    text-align: left;
}

@media screen and (max-width: 768px) {
    p.contact-header {
        text-align: center;
    }
}

@media screen and (max-width: 400px) {
    p.contact-header {
        font-size: 1.4em;
        text-align: left;
    }
}

.contact-input {
    border: solid 1px #6a219a;
    width: 500px;
    width: 100%;
    color: #6a219a !important;
}

@media screen and (max-width: 1024px) {
    .contact-input {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .contact-input {
        width: 100%;
    }
}

@media screen and (max-width: 400px) {
    .contact-input {
        width: 300px;
        margin-left: 10px;
    }
}

.grid-contact-item1 {
    grid-area: form;
    display: grid;
    grid-template-columns: auto auto;
}

@media only screen and (max-width: 1024px) {
    .grid-contact-item1 {
        display: grid;
        grid-template-columns: 1fr;
    }
}

.grid-contact-item2 {
    grid-area: address;
}

.grid-contact-item3 {
    grid-area: bottom;
}

.grid-contact-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-areas: "form" "address" "bottom";
    grid-gap: 15px;
}

@media only screen and (max-width: 1024px) {
    .grid-contact-container {
        display: grid;
        grid-template-columns: 1fr;
    }
}

.grid-contact-container-submit {
    display: grid;
    grid-template-columns: auto;
}

.contact-grid {
    display: grid;
    grid-template-columns: 1fr;
}

@media screen and (max-width: 1400px) {
    .contact-grid {
        display: grid;
        grid-template-columns: 1fr;
    }
}

.contact-text {
    text-align: center;
}

@media screen and (max-width: 400px) {
    .contact-text {
        text-align: left;
    }
}

hr {
    width: 50%;
}

.footer-link {
    border-bottom: 3px solid transparent;
    color: #FFFFFF !important;
    font-size: 13px;
    font-weight: bold !important;
    text-transform: uppercase;
    font-family: "DIN W01 Bold";
}

.footer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    width: 100%;
    text-align: center;
    font-family: "DIN W01 Bold";
    background-color: #394e6f;
    color: #FFFFFF;
    padding: 20px 0 0 20px;
}

.footer-social {
    padding-bottom: 20px;
}

.title-footer {
    font-family: "DIN W01 Bold";
    font-size: 8vh;
    font-weight: bold;
    margin: -1vh;
    color: #394e6f;
}

@media screen and (max-width: 768px) {
    .title-footer {
        font-size: 6vh;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 375px) {
    .title-footer {
        font-size: 3vh;
        margin-left: auto;
        margin-right: auto;
    }
}

.logo-footer {
    width: 15%;
}

@media screen and (max-width: 800px) {
    .logo-footer {
        width: 10%;
    }
}

.social-container {
    background: #eee;
    padding: 25px 50px;
}

a.social {
    margin: 0 1rem;
    transition: transform 250ms;
    display: inline-block;
}

a.social:hover {
    transform: translateY(-2px);
}

a.youtube {
    color: #eb3223;
}

a.facebook {
    color: #4968ad;
}

a.twitter {
    color: #49a1eb;
}

a.instagram {
    color: #E1306C;
}

/* Animation webkit */
@-webkit-keyframes myfirst {
    0% {
        margin-left: -235px;
    }

    90% {
        margin-left: 100%;
    }

    100% {
        margin-left: 100%;
    }
}

/* Animation */
@keyframes myfirst {
    0% {
        margin-left: -235px;
    }

    70% {
        margin-left: 100%;
    }

    100% {
        margin-left: 100%;
    }
}

.card-img-top {
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
    width: 100%
}

#form {
    background-color: transparent;
    overflow: hidden;
    position: relative;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

#waterform {
    margin: 0 auto;
    width: 500px;
    /* padding-top: 40px; */
    color: #394e6f;
    position: relative;
}

form.name-form,
form.email-form,
form.lastname-form,
form.phone-form,
form.sel1,
label.contact-label,
input.contact-input,
textarea.contact-textarea {
    display: block;
}


select.contact-select,
input.contact-input,
textarea.contact-textarea {
    /* width: 500px; */
    margin-left: 30px;
    border: none;
    border-radius: 5px;
    outline: none;
    padding: 10px;
    font-family: "DIN W01 Bold";
    font-size: 1.5em;
    color: #6a219a;
    transition: border 0.5s;
    -webkit-transition: border 0.5s;
    -moz-transition: border 0.5s;
    -o-transition: border 0.5s;
    border: solid 3px #6a219a;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

textarea {
    height: 100px;
    resize: none;
    overflow: auto;
}

input[type=submit] {
    background-color: #6a219a;
    color: #ac9551;
    height: 50px;
    cursor: pointer;
    margin-top: 30px;
    margin-left: 30px;
    font-size: 1.35em;
    -webkit-transition: background-color 0.5s;
    -moz-transition: background-color 0.5s;
    -o-transition: background-color 0.5s;
    transition: background-color 0.5s;
}

input[type=submit]:hover {
    background-color: lightgray;
    color: #394e6f;
}

label.contact-label {
    font-size: 1.5em;
    margin-top: 20px;
    padding-left: 20px;
    margin-left: 20px;
}