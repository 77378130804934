@font-face {
  font-family: "Gistesy";
  /*Can be any text*/
  src: local("Gistesy"),
    url("./../assets/fonts/Gistesy.ttf") format("truetype");
}


body {
  background-color: #dcc4ec;
}


.MuiCardHeader-title {
  color: #c5a87cfc!important;
  font-size: 1.2rem!important;
  font-weight: bold!important;
  padding: 0;
}

.MuiCardHeader-subheader {
  color: #c5a87cfc!important;
  font-size: 0.8rem !important;
  font-weight: bold !important;
  padding: 0;
  text-align: center;
}

.MuiCardContent-root {
  padding: 30px!important;
}

.navbar-grid {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  justify-content: center;
  grid-column-gap: 10px;
}

.iq-image {
  position: absolute;
  top: 0%;
  left: 40%;
  width: 60%;
  transition: width 5s;
  transition-timing-function: linear;
  z-index: 0;
}

.iq-image:hover {
  width: 30%;
}

.cookie-banner{
  position:absolute;
  top: 430px;
  left: 38%;
  border: 4px solid #6da4b5;
  padding: 15px;
}

@media(max-width: 420px) {
  .cookie-banner {
    font-size: 30px;
    top: 18rem;
    left: 0%;
    color: #6a219a!important;
  }
}


.about {
  width: 100%;
}

.about-text-header {
  margin-left: auto;
  margin-right: auto;
}

.about-heading {
  /* font-family: "DIN W01 Bold"; */
  font-size: 34px;
  color: #394e6f;
  margin-top: 4.5rem;
  text-align: center;
}

.about-subheading {
  /* font-family: "DIN W01 Bold"; */
  font-size: 30px;
  color: #394e6f;
  font-weight: bold;
  margin-top: 1rem;
  text-align: center;
}

.topic-header-about {
  margin-left: auto;
  margin-right: auto;
  display: block;
  text-align: center;
}

.topic-header {
  text-align: center
}

.lorem {
  color: #394e6f;
  /* font-family: "DIN W01 Bold"; */
  font-size: 20px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.lorem1 {
  color: #394e6f;
  /* font-family: "DIN W01 Bold"; */
  font-weight: bold;
  font-size: 25px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.lorem1_0 {
  color: #6da4b5;
  /* font-family: "DIN W01 Bold"; */
  font-weight: 900;
  font-size: 25px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.lorem2 {
  color: #394e6f;
  text-shadow: 1px 1px 2px #000000;
  /* font-family: "DIN W01 Bold"; */
  font-size: 20px;
  width: 90%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.lorem2:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.title {
  color: #6a219a;
  font-weight: 800!important;
}

.subtitle {
color: #6a219a;
  font-weight: 400 !important;
}

 @media(max-width: 400px) {
   .subtitle {
     font-size: 1rem!important;
   }
 }


.paper-back {
  background: linear-gradient(-45deg, #6a219a, white, white, #6a219a);
  display: block;
}

.oem-carousel {
  display: block;
  max-width: 100%;
  height: 540px;
  min-height: 500px;
  opacity: 1;
  margin: 0 auto;
  box-shadow: 3px 3px 5px #6a219a;
}

 @media(max-width: 1440px) {
   .oem-carousel {
     min-height: 200px;
     max-width: 100%;
   }
 }

 @media(max-width: 700px) {
   .oem-carousel {
     min-height: 200px;
     max-width: 90%;
   }
 }

 @media(max-width: 420px) {
   .oem-carousel {
     min-height: 280px;
     max-width: 100%;
   }
 }

.carousel-title {
  display: block;
  text-align: center;
  color: #ac9551;
  text-shadow: 2px 2px 4px #000000;
}

.carousel-home-title {
    display: inline;
    /* text-align: center;
    font-size: 1.5rem; */
}

.row {
  display: block;
  /* height: calc(100% - 60px); */
}

.footer-about {
  margin-top: 400px;
}

.content-container {
  min-height: calc(100vh + 20px);
  display: flex;
  flex-direction: column;
  margin-top: -5px;
}

@media(max-width: 1244px) {
  .content-container {
    min-height: calc(100vh + 500px);
  }
}

@media(max-width: 840px) {
  .content-container {
    min-height: calc(100vh + 1000px);
  }
}

@media(max-width: 400px) {
  .content-container {
    min-height: calc(100vh + 2200px);
  }
}

.system {
  display: flex;
  color: #000;
  font-weight: bold;
  min-height: 60vh;
  margin-top: -500px;
  justify-content: center;
  z-index: 1;
  text-decoration: none;
}



.img-fluid {
  max-width: 100%;
  height: auto;
}

.contact-form-login {
    margin: 0 auto;
    width: 100%;
    color: #394e6f;
    position: relative;
}

.home-cards {
  display: grid;
  grid-template-columns: 3fr 3fr 3fr 3fr;
  margin-top: 10px;
  grid-column-gap: 10px;
}

 @media(max-width: 768px) {
   .home-cards {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    margin-left: 0!important;
    /* height: 1400px; */
   }
 }

  @media(max-width: 420px) {
    .home-cards {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      margin-left: 0 !important;
      /* height: 1400px; */
    }
  }

 .home-card {
  display: block;
   margin: 0 auto;
   justify-content: center;
 }

 @media(max-width: 768px) {
   .home-card {
    margin: 0 0 26px auto;
     justify-content: center;
   }
 }

 @media(max-width: 428px) {
   .home-card {
     margin:  0 0 26px auto;
     justify-content: center;
   }
 }

.menu-hover:hover {
  color: #dcc4ec;
  font-size:  1.5rem;
}

.menu-hover:active {
  color: #dcc4ec;
  font-size: 1.5rem;
}

.footer-title {
    color: #6a219a;
}


.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  padding: 10px;
  cursor: pointer;
  background-color: #6a219a;
  border: none;
  color:#ac9551;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  vertical-align: middle;
  display: flex;
}

.dropdown-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #6a219a;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}

.dropdown-menu li {
  padding: 10px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background-color: #ac9551;
}

.side-text {
  position: absolute;
  /* top: 205px!important; */
  /* left: 20px; */
  width: 230px;
  z-index: 2;
  font-family: Gistesy;
  font-size: 1.5rem!important;
  font-weight: 700;
  margin-bottom: 10px!important;
  margin-top: -10px !important;
  color:white!important;
  background: #6a219a!important;
}

.grid-numbers {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: 20px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 400px) {
  .grid-numbers {
    grid-template-columns: repeat(1, 1fr);
  }
}

.news-logo {
  width: 30%;
  height: auto;
  margin-left: 20px;
  /* background: #DCC4E8; */
}

@media only screen and (max-width: 768px) {
  .news-logo {
    width: 45%;
    height: auto;
  }
}

@media only screen and (max-width: 420px) {
  .news-logo {
    width: 95%;
    height: auto;
  }
}

.whatsapp-icon-aux {
  cursor: pointer;
}
.whatsapp-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: #fff;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.qr-code {
  position: absolute;
  top: -160px;
  /* Adjust this value based on your layout */
  left: 50%;
  transform: translateX(-80%);
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.newsTitle {
  color: #6a219a;
  font-size: 1.5rem;
  text-align: center;
  margin: 5px;
  /* padding-top: 0.1rem; */
}

.newsQuestion {
  margin: 10px;
  color: #6a219a;
  font-size: 1.0rem;
  text-align: center;
  /* padding-top: 0.1rem; */
}
